import { compile, match } from 'path-to-regexp';

/**
 *  Compiler 관련
 */
// export const urlCompiler = <Args extends Record<string, string | number>>(path: string) => {
//   return {
//     query(object: Args): string {
//       const keys = Object.keys(object);

//       const suffix = keys.reduce((prev, key: string, idx: number) => {
//         const separator = idx === 0 ? '?' : '&';
//         return prev += separator + key + '=' + object[key];
//       }, '')

//       return path + suffix;
//     },
//     params(object: Args): string {
//       const keys = Object.keys(object);

//       const suffix =  keys.reduce((prev, key: string) => {
//         return prev += `/${object[key]}`;
//       }, '')

//       return path + suffix;
//     },
//   };
// }

export const urlCompiler = <
  Args extends Record<string, void | null | boolean | number | string>,
  P extends string = string,
>(
  path: P,
) => {
  type PathSegmentation<R extends string> = R extends `/:${infer K}/${infer V}`
    ? { readonly [Key in K]: string } & PathSegmentation<`/${V}`>
    : R extends `/${infer K}/${infer V}` // eslint-disable-line
    ? PathSegmentation<`/${V}`>
    : R extends `/:${infer K}`
    ? { readonly [Key in K]: string }
    : {};

  const compiler = compile(path);
  const matcher = match(path);

  return {
    both(item: PathSegmentation<P>, args: Args): string {
      const urlSearchParams = new window.URLSearchParams(
        Object.fromEntries(
          Object.entries(args).filter(([_, value = null]) => value !== null) as Array<[string, string]>,
        ),
      );
      return [compiler(item), urlSearchParams.toString()].filter(Boolean).join('?');
    },
    args(args: Args): string {
      const urlSearchParams = new window.URLSearchParams(
        Object.fromEntries(
          Object.entries(args).filter(([_, value = null]) => value !== null) as Array<[string, string]>,
        ),
      );
      return [path, urlSearchParams.toString()].filter(Boolean).join('?');
    },
    path(item: PathSegmentation<P>): string {
      return compiler(item);
    },
    isMatch(path: string): boolean {
      return !!matcher(path);
    },
  };
};
