export enum ResponseType {
  IDLE = 'idle',
  ERROR = 'error',
  LOADING = 'loading',
  SUCCESS = 'success',
}

type ApiResponseMessageType = {
  readonly text: string;
  readonly type: string;
};

export type ApiResponseType = {
  readonly data: any;
  readonly mcode: number;
  readonly success: boolean;
  readonly message: ApiResponseMessageType;
};

export enum ErrorMessageCode {
  SUCCESS = 0,
  UNKNOWN_SERVER_ERROR = 1,
  WRONG_PARAM = 4,
  AUTH_REQUIRED = 1000,
  INVALID_TOKEN = 1001,
  EXPIRED_TOKEN = 1002,
  WRONG_EMAIL_PASSWORD = 1003,
  EXIST_EMAIL = 1004,
  ADMIN_AUTH_REQUIRED = 1006,
  NOT_EXIST_USER = 2000,
  INVALID_USER = 2001,
}

export enum ErrorMessageName {
  // SUCCESS = 'SUCCESS',
  UNKNOWN_SERVER_ERROR = 'UNKNOWN_SERVER_ERROR',
  WRONG_PARAM = 'WRONG_PARAM_ERROR',
  AUTH_REQUIRED = 'AUTH_REQUIRED_ERROR',
  INVALID_TOKEN = 'INVALID_TOKEN_ERROR',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN_ERROR',
  WRONG_EMAIL_PASSWORD = 'WRONG_EMAIL_PASSWORD_ERROR',
  EXIST_EMAIL = 'EXIST_EMAIL_ERROR',
  NOT_EXIST_USER = 'NOT_EXIST_USER_ERROR',
  INVALID_USER = 'INVALID_USER_ERROR',
}

export enum PROJECT_CLOSE_STATUS {
  PENDING = 'PENDING', // "마감 안됨"  # 기본 값, 별도의 마감 없음.
  AUTO_CLOSE = 'AUTO_CLOSE', // "자동마감"  # 자동 마감 된 경우
  OPERATOR_CLOSE = 'OPERATOR_CLOSE', // "오퍼레이터 강제 마감"  # 오퍼레이터가 강제로 마감한 경우
  ERROR_CLOSE = 'ERROR_CLOSE', // "알수없는 마감" # 자동마감, 오퍼레이터 강제 마감 외 마감된 경우
}
