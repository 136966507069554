import { useMutation, useQuery } from 'react-query';

// apis
import { getLoginUserProfileApi, loginApi } from '@apis/auth';

// types
import { ACCESS_TOKEN, getCookie, REFRESH_TOKEN, removeCookie, setCookie } from '@utils/cookie';
import {
  AuthPostAdminLoginError,
  AuthPostAdminLoginPayload,
  UserGetAdminUserProfileError,
} from 'models/swagger/data-contracts';
import { AxiosError } from 'axios';
import { Toast } from '@components/atoms/Toast';
import { MINUTE, MONTH } from '@constants/time';

/**
 * 로그인 Fetch
 * @function useLoginFetch
 */
export const useLoginFetch = () => {
  const {
    mutate: loginFetchMutate,
    status: loginFetchStatus,
    isLoading: loginFetchIsLoading,
  } = useMutation(
    ['login'],
    ({ email, password }: AuthPostAdminLoginPayload) => loginApi({ email: email, password: password }),
    {
      onSuccess: (res) => {
        const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;

        setCookie(ACCESS_TOKEN, res.data.data?.access_token, { maxAge: 5 * MINUTE, domain: currentDomain, path: '/' });
        setCookie(REFRESH_TOKEN, res.data.data?.refresh_token, { maxAge: 3 * MONTH, domain: currentDomain, path: '/' });
      },
      onError: (err: AxiosError<AuthPostAdminLoginError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response?.data?.message.text);
        }
      },
    },
  );

  return {
    loginFetchIsLoading,
    loginFetchMutate,
    loginFetchStatus,
  };
};

/**
 *  로그인 유저 정보 조회 Fetch
 *  @function useGetLoginUserProfileFetch
 */
export const useGetLoginUserProfileFetch = () => {
  const accessToken = getCookie(ACCESS_TOKEN);
  const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;

  const { data: loginUserProfileData, refetch: getLoginUserProfileRefetch } = useQuery(
    ['user', accessToken],
    async function () {
      const result = await getLoginUserProfileApi();
      return result.data.data;
    },
    {
      staleTime: 5 * 60 * 1000, // ms 단위
      enabled: !!accessToken,
      onError: (err: AxiosError<UserGetAdminUserProfileError>) => {
        if (err.response?.data?.message) {
          Toast.error(err.response?.data?.message.text);

          removeCookie(ACCESS_TOKEN, { domain: currentDomain, path: '/' });
          removeCookie(REFRESH_TOKEN, { domain: currentDomain, path: '/' });
        }
      },
    },
  );

  return {
    loginUserProfileData,
    getLoginUserProfileRefetch,
  };
};
