import React from 'react';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import { NextComponentType } from 'next';
import Head from 'next/head';

import '../styles/globals.css';

import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot } from 'recoil';
import { CustomToastConatiner } from '@components/atoms/Toast';
import axios, { AxiosError } from 'axios';
import { setSentryDefaultAxiosErrorScope } from '@utils/SentryManager/setSentryDefaultAxiosErrorScope';
import SentryManager from '@utils/SentryManager';
import { SECOND } from '@constants/time';

const MyApp: NextComponentType<AppContext, AppInitialProps, AppProps> = ({ Component, pageProps }) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1 * 1000, // ms 단위라 1000 곱해줌
            cacheTime: Infinity,
            refetchOnWindowFocus: false,
            useErrorBoundary: true,
            onError: (err: unknown) => {
              if (axios.isAxiosError(err)) {
                const axiosError = err as AxiosError<any>;

                setSentryDefaultAxiosErrorScope(axiosError);
              }
            },
          },
          mutations: {
            retry: 0,
            onError: (err: unknown) => {
              if (axios.isAxiosError(err)) {
                const axiosError = err as AxiosError<any>;

                setSentryDefaultAxiosErrorScope(axiosError);
              }
            },
          },
        },
      }),
  );

  return (
    <>
      <Head>
        <title>Steelboso admin</title>
      </Head>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <SentryManager>
              <CustomToastConatiner limit={3} newestOnTop />
              <Component {...pageProps} />
            </SentryManager>
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </RecoilRoot>
    </>
  );
};

// MyApp.getInitialProps = async ({ Component, ctx }: AppContext): Promise<AppInitialProps> => {
//   let pageProps = {};

//   if (Component.getInitialProps) {
//     pageProps = await Component.getInitialProps(ctx);
//   }

//   return { pageProps };
// };

export default MyApp;
