import { useGetLoginUserProfileFetch } from '@hooks/fetch/useAuthFetch';
import { setUser } from '@sentry/nextjs';
import { ReactNode, useEffect } from 'react';

type Props = {
  readonly children: ReactNode;
};

export default function SentryManager({ children }: Props) {
  const { loginUserProfileData: user } = useGetLoginUserProfileFetch();

  useEffect(() => {
    if (!!user) {
      setUser({
        email: user.email,
        id: user.id?.toString(),
        username: user.name,
      });
    } else {
      setUser(null);
    }
  }, [user]);

  return <>{children}</>;
}
