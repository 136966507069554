import styled from '@emotion/styled';
import { green, primary, red, white } from '@styles/Colors';
import { Radius } from '@styles/Radius';
import { ReactNode } from 'react';
import { toast, ToastContainer as ReactToastContainer, ToastOptions, ToastPromiseParams } from 'react-toastify';
import Icon from '../Icon';

export const CustomToastConatiner = styled(ReactToastContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .Toastify__toast {
    margin-bottom: 80px;
    background-color: ${primary.gray};
    border-radius: ${Radius.SMALL};
    color: ${white};
    min-width: fit-content;
    height: fit-content;
    padding: 0 12px;
  }
  .Toastify__toast-icon {
    width: 24px;
    height: 24px;
  }
  .Toastify__toast--default {
  }
  .Toastify__toast--info {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--pending {
  }
`;

const defaultToastOption: ToastOptions = {
  position: 'bottom-center',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  closeButton: false,
  pauseOnFocusLoss: false,
  style: {
    fontFamily: 'Pretendard Variable',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    height: 'min-content',
  },
};

//icon 커스텀 가능
export const Toast = {
  default: (message: ReactNode, options: ToastOptions = {}) => {
    toast(message, { ...defaultToastOption, ...options });
  },
  info: (message: ReactNode, options: ToastOptions = {}) => {
    toast.info(message, { ...defaultToastOption, ...options });
  },
  success: (message: ReactNode, options: ToastOptions = {}) => {
    toast.success(message, {
      ...defaultToastOption,
      ...options,
      icon: <Icon name="check-circle" fill={green.green1} stroke={green.green4} />,
    });
  },
  error: (message: ReactNode, options: ToastOptions = {}) => {
    toast.error(message, {
      ...defaultToastOption,
      ...options,
      icon: <Icon name="alert-circle" fill={red.red1} stroke={red.red4} />,
    });
  },
  warning: (message: ReactNode, options: ToastOptions = {}) => {
    toast.warning(message, {
      ...defaultToastOption,
      ...options,
    });
  },
  promise: <TData = unknown, TError = unknown, TPending = unknown>(
    promise: Promise<TData> | (() => Promise<TData>),
    { pending, error, success }: ToastPromiseParams<TData, TError, TPending>,
    options: ToastOptions = {},
  ): Promise<TData> => {
    return toast.promise(
      promise,
      { pending, error, success },
      {
        ...defaultToastOption,
        ...options,
        icon: ({ type }) =>
          type === 'success' ? (
            <Icon name="check-circle" fill={green.green1} stroke={green.green4} />
          ) : (
            <Icon name="loading" />
          ),
      },
    );
  },
};
